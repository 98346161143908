import React from 'react';
import styled from 'styled-components';
import { margins, contentWidth, Column } from '../home/v2/StyledComponents';
import { H2, H3 } from './style';

import imgThird from '../../assets/whyTilli/PhotoWhyTilli2.jpg';
import imgHalf from '../../assets/howItWorks/PhotoHowItWorks2.jpg';
import imgFull from '../../assets/assurance-3.jpg';

const ImageContainer = styled.div`
  margin: ${margins.s} 0px;
`;

const Third = styled.img`width: calc(${contentWidth}px/3 - 2*${margins.m});`;
const Haft = styled.img`width: calc(${contentWidth}px/2);`;
const Full = styled.img`width: ${contentWidth}px;`;

const Images = () => (
  <div>
    <H2>Images</H2>
    <Column>
      <ImageContainer>
        <H3>Tier de la largeur</H3>
        <Third src={imgThird} alt="Image sur le tier de la largeur" />
      </ImageContainer>

      <ImageContainer>
        <H3>Moitié de la largeur</H3>
        <Haft src={imgHalf} alt="Image sur moitié de la largeur" />
      </ImageContainer>

      <ImageContainer>
        <H3>Toute la largeur </H3>
        <Full src={imgFull} alt="Image sur toute la largeur" />
      </ImageContainer>
    </Column>

  </div>
);

export default Images;
