import React from 'react';
import Popup from '../Popup';
import '../popup.css';
import { H2 } from './style';
import { Button, Title2 } from '../styledComponents';
import Modal from '../Modal';

function openPopup() {
  Popup.create({
    title: 'Aide',
    content: (<div>
      {`
      Cette estimation nous permet d'avoir une idée de la quantité de travail mais
      ce n'est pas grave si elle n'est pas exacte.
      En effet, votre tilliste pourra réajuster cette estimation lors de votre rdv.
      Vous pourrez par exemple ajouter d'autres vêtements à retoucher.
    `}
      <br />
      <br />
      {`
      Vous pouvez aussi nous poser vos questions par chat (en bas à droite de la fenêtre)
      ou par mail à contact@tilli.fr
    `}
      <br />
      <br />
      {`
      Vous ne savez vraiment pas quoi choisir ? Pas de problème, vous pouvez aussi passer cette étape 😉
    `}
              </div>),
    buttons: {
      right: [{
        className: 'success',
        text: 'Action 1',
        action() { Popup.close(); },
      }],
      left: [{
        text: 'Action 2',
        action() { Popup.close(); },
      }],
    },
  });
}

class Modals extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        {(typeof document !== 'undefined') && <Popup />}
        <Modal show={this.state.showModal}>
          <div>
            <Title2>
              Choix des retouches pour Robe
            </Title2>
            <Button onClick={() => this.setState({ showModal: false })}>
              Fermer
            </Button>
          </div>
        </Modal>
        <H2>Modal et popup</H2>
        <div>
          <Button onClick={() => this.setState({ showModal: true })}>
            Ouvrir modal
          </Button>
        </div>
        <div>
          <Button onClick={openPopup}>
            Ouvrir popup d’aide
          </Button>
        </div>
      </div>
    );
  }
}

export default Modals;
