const noop = () => {};
noop.create = function () {};

// don't require react-popup for static build
// otherwise it crashes because document is not defined during the static build phase
const Popup = (typeof document !== 'undefined')
  ? require('react-popup') // eslint-disable-line global-require
  : noop;

export default Popup;
