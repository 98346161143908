import React from 'react';
import styled from 'styled-components';
import {
  ViewPager, Frame, Track, View,
} from 'react-view-pager';
import './font.css';
import { H2 } from './style';
import {
  fontSizes, margins, fontWeight, mobileThresholdPixels, Row, LineV,
} from '../home/v2/StyledComponents';

import arrowLeft from '../../assets/theyLoveTilli/arrow.left.navy.svg';
import arrowRight from '../../assets/theyLoveTilli/arrow.right.navy.svg';

// cSpell:disable
const feedbacks = [
  {
    index: 0,
    text: (
      <span style={{ fontSize: `${fontSizes.s}` }}>
        Lucas ipsum dolor sit amet lars grievous maul ben gamorrean ewok darth antilles yoda darth.
        Yavin lando amidala c-3po jango maul vader dooku.
      </span>
    ),
    author: 'Auteur n°1',
  },
  {
    index: 1,
    text: (
      <span style={{ fontSize: `${fontSizes.s}` }}>
        Skywalker leia calrissian jinn obi-wan dagobah. Droid mothma jawa windu padmé han calrissian solo.
        Utapau sebulba dooku skywalker r2-d2 wicket darth tusken raider moff.
      </span>
    ),
    author: 'Auteur n°2',
  },
  {
    index: 2,
    text: (
      <span style={{ fontSize: `${fontSizes.s}` }}>
        Hutt solo aayla tatooine ackbar windu. Wedge jabba skywalker darth mon mon.
        C-3po bespin kamino leia yoda darth. Mothma ahsoka obi-wan qui-gon wicket moff.
      </span>
    ),
    author: 'Auteur n°3',
  },
  {
    index: 3,
    text: (
      <span style={{ fontSize: `${fontSizes.s}` }}>
        Skywalker leia calrissian jinn obi-wan dagobah. Droid mothma jawa windu padmé han calrissian solo.
        Utapau sebulba dooku skywalker r2-d2 wicket darth tusken raider moff.
      </span>
    ),
    author: 'Auteur n°4',
  },
  {
    index: 4,
    text: (
      <span style={{ fontSize: `${fontSizes.s}` }}>
        Lucas ipsum dolor sit amet lars grievous maul ben gamorrean ewok darth antilles yoda darth.
        Yavin lando amidala c-3po jango maul vader dooku.
      </span>
    ),
    author: 'Auteur n°5',
  },
];
// cSpell:enable

const frameStyle = {
  width: '1200px',
};

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
};

const ControlContainer = styled.div`
  z-index: 1;
  width: 2%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 6%;
  }
`;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
`;

const FeedbackContainer = styled.div`
  margin: 0px ${margins.m};
`;

const AuthorNameSpan = styled.span`
  display: flex;
  font-size: ${fontSizes.s};
  font-weight: bold;
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const TextContainer = styled.div`
  font-weight: ${fontWeight.medium};
`;

const FeedbackDots = styled.span`
  display: flex;
  font-size: ${fontSizes.x3l};
  font-weight: bold;
  font-family: Roboto;
  letter-spacing: 6px;
  margin-top: -32px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.x4l};
    margin-top: -40px;
  }
`;

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: 0,
      nextView: 1,
    };
  }

  render() {
    return (
      <div>
        <H2>Commentaires</H2>
        <ViewPager tag="main" style={viewPagerStyle}>
          <ControlContainer right>
            <ControlImg
              alt="Commentaire précédent sur Tilli, votre couturier à domicile"
              src={arrowLeft}
              onClick={() => this.track.prev()}
            />
          </ControlContainer>
          <Frame className="frame" style={frameStyle}>
            <Track
              ref={(c) => { this.track = c; }}
              viewsToShow={3}
              infinite
              onViewChange={() => this.setState({
                currentView: (this.track.context.pager.currentView.index),
                nextView: (this.track.context.pager.currentView.index + 1) % 5,
              })}
              className="track"
            >
              {feedbacks.map((feedback) => (
                <View className="view" key={feedback.index}>
                  <Row justifyContent="space-between" alignContent="center" alignItems="flex-start">
                    <FeedbackContainer>
                      <AuthorNameSpan>
                        {feedback.author}
                        &nbsp;
                        <FeedbackDots>..... </FeedbackDots>
                      </AuthorNameSpan>
                      <TextContainer>
                        {' '}
                        {feedback.text}
                        {' '}
                      </TextContainer>
                    </FeedbackContainer>
                    {(this.state.currentView === feedback.index || this.state.nextView === feedback.index)
                      && <LineV height="115px" />}
                  </Row>
                </View>
              ))}
            </Track>
          </Frame>
          <ControlContainer right>
            <ControlImg
              alt="Commentaire suivant sur Tilli, votre couturier à domicile"
              src={arrowRight}
              onClick={() => this.track.next()}
            />
          </ControlContainer>
        </ViewPager>
      </div>
    );
  }
}

export default Feedback;
